.Navbar {
    background-color: #0F0F10;
    display: flex;
    height: 12vh;
    font-family: sans-serif;
    color:'whitesmoke';
    justify-content: space-between;
    padding: 20px;
    align-items: center;
  }

.NavItem {
    padding-left: 1em;
    padding-right: 1em;
}