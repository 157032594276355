body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #050506;
  color: whitesmoke
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5 {
  text-align: center;
  padding: 0;
}

h4 {
  font-size: .8em;
}

.MapView {
  display: flex;
  flex-direction: row;
  height: 88vh;
}

.MapAndSidebar {
  height: 88vh;
  width: 70vw;
  display: flex;
  flex-direction: column;
}
.Map {
  height: 65%;
  width: 100%;
} 
.Sidebar {
  display: flex;
  flex-direction: row;
  height: 35%;
}
.Dropdown {
  position: absolute; 
  left: 0%;
  top: 43%; 
  z-index: 2;
  width: 70%;
}
div.top-200 {
  height: 100%;
  width: 50%;
}
div.regional-farms {
  height: 100%;
  width: 50%;
}
.Sidebar h4 {
  height: 8vh;
}
ul {
  height: 20vh;
}
div.mapboxgl-popup-content {
  background-color: #212529;
  color: whitesmoke;
  padding: 0;
  z-index: 20;
}
button.mapboxgl-popup-close-button {
  color: whitesmoke;
  padding: 0;
}

@media only screen and (min-width: 800px) {
  /* For tablets: */
  h4 {
    font-size: 1em;
  }
  .MapAndSidebar {
    display: flex;
    flex-direction: row;
  }
  .Map {
    height: 88vh;
    width: 70%;
  }
  .Sidebar {
    flex-direction: column;
    height: 88vh;
    width: 30%;
  }
  .Dropdown {
    position: absolute; 
    left: 23%;
    z-index: 2;
    top: 14%; 
    width: 25%;
  }
  div.top-200 {
    height: 44vh;
    width: 100%;
  }
  div.regional-farms {
    height: 44vh;
    width: 100%;
  }
  ul {
    height: 33vh;
  }
 

}

div[data-amplify-authenticator] [data-amplify-container] {
  width: 100%;
}


@media only screen and (min-width: 875px) {
  /* For tablets: */
  ul.regional-farms {
    height: 36vh;
  }
 
}

@media only screen and (min-width: 1250px) {
  /* For tablets: */
  ul {
    height: 36vh;
  }
  
}

